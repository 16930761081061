<!-- Custom Template -->
<div
  *ngIf="standTemplate.standTemplateType === 'Custom'"
  class="stand-template"
>
  <div
    [style.background-color]="standTemplate.template.cardTop.background"
    class="img-container"
  >
    <!-- Card Top -->
     <div style="justify-content: center; flex-basis: 66%; align-items: center;" class="d-flex">
      <img class="logo" [src]="standTemplate?.logo ? 'data:image/jpg;base64,' + standTemplate.logo : customerLogo" />
     </div>
  </div>

  <div
    [style.background-color]="standTemplate.template.cardMiddle.background"
    [style.color]="standTemplate.template.cardMiddle.text"
    class="card-middle"
  >
    <!-- Card Middle -->
    <div class="avatar-container">
      <!-- Photo Container -->
      <img
        class="default-avatar"
        src="assets/images/default-avatar.png"
        alt="default-avatar"
      />  
    </div>

    <div class="card-middle-text">
      <!-- Column 1 -->
      <div class="middle-text-wrapper">
        <!-- Row 1 -->
        <div class="d-flex" style="align-items: center;">
          <div class="circle">1</div>
          <div style="justify-content: space-between;">
            <span *ngIf="standTemplate.template.firstPosition[0]">
              {{ getPropertyName(standTemplate.template.firstPosition[0]) }}
            </span>
            <span *ngIf="standTemplate.template.firstPosition[1]">
              {{ getPropertyName(standTemplate.template.firstPosition[1]) }}
            </span>
          </div>
        </div>
        <span
          class="d-flex"
          style="margin-top: 10px; align-items: center"
        >
          <div class="circle">2</div>
          <span *ngIf="standTemplate.template.secondPosition">{{ getPropertyName(standTemplate.template.secondPosition) }}</span>
        </span>
      </div>

      <div class="middle-text-wrapper" style="align-items: flex-end">
        <span
          class="d-flex"
          style="align-items: center"
        >
          <div class="circle">3</div>
          <span *ngIf="standTemplate.template.thirdPosition">{{ getPropertyName(standTemplate.template.thirdPosition) }}</span>
        </span>
      </div>
    </div>
  </div>

  <!-- Card Bottom -->
  <div
    [style.color]="standTemplate.template.cardBottom.text"
    [style.background-color]="standTemplate.template.cardBottom.background"
    class="card-bottom"
    style="min-height: 108px"
  >
    <div class="card-bottom-left">
      <div
        style="margin-bottom: 15px"
      >
        <span style="font-size: 9px; align-items: center" class="d-flex">
          <div class="circle">4</div>
          <span *ngIf="standTemplate.template.fourthPosition">{{ getPropertyName(standTemplate.template.fourthPosition) }}</span>
        </span>
        <br />
      </div>
      <div
        style="margin-bottom: 15px"
      >
        <span style="font-size: 9px; align-items: center" class="d-flex">
          <div class="circle">5</div>
          <span *ngIf="standTemplate.template.fifthPosition">{{ getPropertyName(standTemplate.template.fifthPosition) }}</span>
        </span>
        <br />
      </div>
      <div class="gif-container" style="margin-left: 15px">
        <img
          class="gif"
          src="assets/images/security-image.png"
          alt="security-gif"
        />
      </div>
    </div>

    <div class="card-bottom-right">
      <img class="qr-code" src="assets/images/qr-code.png" alt="qr-code" />
      <span class="clock">{{ date }}</span>
    </div>
  </div>

  <!-- Card Footer -->
  <div class="footer">
    <div>
      <mat-icon>badge</mat-icon>
      <span> ID </span>
    </div>
    <div>
      <mat-icon>account_circle</mat-icon>
      <span>{{ "ERROR.DETAILS" | translate }}</span>
    </div>
    <div>
      <mat-icon>notifications</mat-icon>
      <span>{{ "SIDE_NAV.NOTIFICATIONS" | translate }}</span>
    </div>
    <div>
      <mat-icon>settings</mat-icon>
      <span>{{ "SIDE_NAV.SETTINGS" | translate }}</span>
    </div>
  </div>
</div>

<!-- Event Ticket -->
<div
  *ngIf="standTemplate.standTemplateType === 'EventTicket'"
  class="stand-template"
  style="
    background-color: black;
    border-radius: 20px;
    height: 530px;
    padding-top: 10px;
    padding-inline: 10px;
  "
>
  <div class="d-flex">
    <div
      style="
        background-color: rgb(67, 145, 230);
        height: 23px;
        width: 23px;
        border-radius: 50%;
      "
    >
      <mat-icon style="font-size: small; padding-top: 5px; padding-left: 5px"
        >local_activity</mat-icon
      >
    </div>
    <span style="margin-block: auto; font-size: 11px; margin-left: 10px"
      >TicketShop</span
    >
  </div>
  <mat-divider
    style="border-top-color: rgb(58, 58, 58); margin-block: 10px"
  ></mat-divider>

  <div class="d-flex" style="flex-direction: column; margin-top: 25px">
    <span style="font-size: 11px"> Stay Puft Arena </span>
    <span style="font-size: 25px"> Cobra Waves </span>
  </div>

  <div
    class="d-flex"
    style="justify-content: space-between; margin-top: 20px; font-size: 9px"
  >
    <span>Date</span>
    <span>Time</span>
  </div>

  <div
    class="d-flex"
    style="justify-content: space-between; font-size: 11px; margin-bottom: 10px"
  >
    <span>Tue Mar 8, 2024</span>
    <span>7:00 PM</span>
  </div>

  <mat-divider style="border-top-color: rgb(58, 58, 58)"></mat-divider>

  <div
    class="d-flex"
    style="justify-content: space-between; font-size: 9px; margin-top: 25px"
  >
    <span>Section</span>
    <span style="margin-right: 20px">Row</span>
    <span>Seat</span>
  </div>
  <div class="d-flex" style="justify-content: space-between; font-size: 11px">
    <span>232</span>
    <span style="margin-right: 20px">12</span>
    <span>2</span>
  </div>

  <div
    class="d-flex"
    style="width: 100%; justify-content: center; margin-top: 30px"
  >
    <div
      class="pt-0"
      style="
        border-radius: 7px;
        background-color: white;
        width: fit-content;
        height: fit-content;
      "
    >
      <img
        class="qr-code"
        style="height: 130px; width: 130px"
        src="assets/images/qr-code.png"
        alt="qr-code"
      />
    </div>
  </div>
</div>
